import { LetDirective } from './let-directive';

interface ContextWithImplicit<T> {
  $implicit: T;
}

/**
 * @internal
 */
export class LetContext<T> implements ContextWithImplicit<T> {
  constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

  get $implicit(): T {
    return this.internalDirectiveInstance.let;
  }

  get let(): T {
    return this.internalDirectiveInstance.let;
  }
}
