import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { LetContext } from './let-context';

@Directive({
  selector: '[let]',
  standalone: true,
})
export class LetDirective<T> {
  @Input()
  let!: T;

  constructor(
    @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>,
  ) {
    viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
  }

  static ngTemplateContextGuard<T>(_dir: LetDirective<T>, _ctx: unknown): _ctx is LetContext<T> {
    return true;
  }
}
